<template>
  <!-- swiper1 -->
  <div style="height: 500px">
    <swiper
      ref="swiperTop"
      :options="swiperOptionTop"
      class="gallery-top"
    >
      <swiper-slide class="slide-1" />
      <swiper-slide class="slide-2" />
      <swiper-slide class="slide-3" />
      <swiper-slide class="slide-4" />
      <swiper-slide class="slide-5" />
      <div
        slot="button-next"
        class="swiper-button-next swiper-button-white"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev swiper-button-white"
      />
    </swiper>

    <!-- swiper2 Thumbs -->
    <swiper
      ref="swiperThumbs"
      :options="swiperOptionThumbs"
      class="gallery-thumbs"
    >
      <swiper-slide class="slide-1" />
      <swiper-slide class="slide-2" />
      <swiper-slide class="slide-3" />
      <swiper-slide class="slide-4" />
      <swiper-slide class="slide-5" />
    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'GalleryCarousel',
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    title: 'GalleryCarousel',
    swiperOptionTop: {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    swiperOptionThumbs: {
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 'auto',
      touchRatio: 0.2,
      slideToClickedSlide: true
    }
  }),
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  }
};
</script>


<style lang="scss">
.gallery-top {
  height: 80% !important;
  width: 100%;
}
.gallery-thumbs {
  height: 20% !important;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
</style>