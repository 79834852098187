<template>
  <swiper :options="swiperOption">
    <swiper-slide class="slide-1" />
    <swiper-slide class="slide-2" />
    <swiper-slide class="slide-3" />
    <swiper-slide class="slide-4" />
    <swiper-slide class="slide-5" />
    <div
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>

<script>
import 'swiper/dist/css/swiper.min.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'PaginationCarousel',
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    title: 'PaginationCarousel',
    swiperOption: {
      pagination: {
        el: '.swiper-pagination'
      }
    }
  })
};
</script>