<template>
  <swiper :options="swiperOption">
    <swiper-slide class="slide-1" />
    <swiper-slide class="slide-2" />
    <swiper-slide class="slide-3" />
    <swiper-slide class="slide-4" />
    <swiper-slide class="slide-5" />
    <div
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>

<script>
import 'swiper/dist/css/swiper.min.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'MultislideCarousel',
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    title: 'MultislideCarousel',
    swiperOption: {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20
        }
      }
    }
  })
};
</script>