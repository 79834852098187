import { render, staticRenderFns } from "./VerticalCarousel.vue?vue&type=template&id=f31cd2ba&scoped=true&"
import script from "./VerticalCarousel.vue?vue&type=script&lang=js&"
export * from "./VerticalCarousel.vue?vue&type=script&lang=js&"
import style0 from "./VerticalCarousel.vue?vue&type=style&index=0&id=f31cd2ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f31cd2ba",
  null
  
)

export default component.exports